::selection {
    background-color: #b3d4fc;
    color: #000;
    text-shadow: none;
}
body{
    font-family: 'EMprintW05Regular_web', sans-serif !important;
}
#logocabecera img{
    max-width: 10vw;
    margin: 0 1rem;
    max-height: 40px;
}
.btn_login{
    background-color: #4f87ca;
    border-color: #4f87ca !important;
}
.btn_2{
    background-color: #4f87ca;
    border-color: #4f87ca !important;
}
.authincation{
    background-color: #2369bd;
}
.nav-header .brand-logo{
    background-color: #fff;
    background-image: linear-gradient(#fff,#fff 25%,#e6e6e6);
}
.nav-header .brand-title{
    max-width: 135px;
}
.btn-icon-left_1 {
    background: #fff;
    border-radius: 10rem;
    display: inline-block;
    margin: -0.5rem 0.1rem -0.5rem 0.1rem;
    padding: 0.1rem 0.60rem 0.1rem;
    float: left;
}

h4.text-black{
    font-size: 15px;
    white-space: normal;
}
.navpills-1 span{
    font-size: 12px;
}
.btn_off{
    background-color: transparent;
    color: #000;
}
#btn_subir_interno{
    margin-right: 20px;
    margin-top: 8px;
}
.photo-content .cover-photo{
    background: url(../images/profile/banner_profile.jpg);
}