@font-face {
    font-family: 'EMprintW05BoldItalic_web';
    font-display: swap;
    src: url('EMprint W05 Bold Italic_web.eot');
    src: url('EMprint W05 Bold Italic_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Bold Italic_web.woff2') format('woff2'),
         url('EMprint W05 Bold Italic_web.woff') format('woff'),
         url('EMprint W05 Bold Italic_web.svg#EMprintW05BoldItalic_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'EMprintW05Bold_web';
    font-display: swap;
    src: url('EMprint W05 Bold_web.eot');
    src: url('EMprint W05 Bold_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Bold_web.woff2') format('woff2'),
         url('EMprint W05 Bold_web.woff') format('woff'),
         url('EMprint W05 Bold_web.svg#EMprintW05Bold_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'EMprintW05CondSemiboldIt_web';
    font-display: swap;
    src: url('EMprint W05 Cond Semibold It_web.eot');
    src: url('EMprint W05 Cond Semibold It_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Cond Semibold It_web.woff2') format('woff2'),
         url('EMprint W05 Cond Semibold It_web.woff') format('woff'),
         url('EMprint W05 Cond Semibold It_web.svg#EMprintW05CondSemiboldIt_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'EMprintW05CondensedBoldIt_web';
    font-display: swap;
    src: url('EMprint W05 Condensed Bold It_web.eot');
    src: url('EMprint W05 Condensed Bold It_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Condensed Bold It_web.woff2') format('woff2'),
         url('EMprint W05 Condensed Bold It_web.woff') format('woff'),
         url('EMprint W05 Condensed Bold It_web.svg#EMprintW05CondensedBoldIt_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'EMprintW05CondensedBold_web';
    font-display: swap;
    src: url('EMprint W05 Condensed Bold_web.eot');
    src: url('EMprint W05 Condensed Bold_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Condensed Bold_web.woff2') format('woff2'),
         url('EMprint W05 Condensed Bold_web.woff') format('woff'),
         url('EMprint W05 Condensed Bold_web.svg#EMprintW05CondensedBold_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'EMprintW05CondensedItalic_web';
    font-display: swap;
    src: url('EMprint W05 Condensed Italic_web.eot');
    src: url('EMprint W05 Condensed Italic_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Condensed Italic_web.woff2') format('woff2'),
         url('EMprint W05 Condensed Italic_web.woff') format('woff'),
         url('EMprint W05 Condensed Italic_web.svg#EMprintW05CondensedItalic_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'EMprintW05CondensedLightIt_web';
    font-display: swap;
    src: url('EMprint W05 Condensed Light It_web.eot');
    src: url('EMprint W05 Condensed Light It_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Condensed Light It_web.woff2') format('woff2'),
         url('EMprint W05 Condensed Light It_web.woff') format('woff'),
         url('EMprint W05 Condensed Light It_web.svg#EMprintW05CondensedLightIt_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'EMprintW05CondensedLight_web';
    font-display: swap;
    src: url('EMprint W05 Condensed Light_web.eot');
    src: url('EMprint W05 Condensed Light_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Condensed Light_web.woff2') format('woff2'),
         url('EMprint W05 Condensed Light_web.woff') format('woff'),
         url('EMprint W05 Condensed Light_web.svg#EMprintW05CondensedLight_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

/*
@font-face {
    font-family: 'EMprintW05CondensedRegular_web';
    font-display: swap;
    src: url('mEMprint W05 Condensed Regular_web.eot');
    src: url('mEMprint W05 Condensed Regular_web.eot?#iefix') format('embedded-opentype'),
         url('mEMprint W05 Condensed Regular_web.woff2') format('woff2'),
         url('mEMprint W05 Condensed Regular_web.woff') format('woff'),
         url('mEMprint W05 Condensed Regular_web.svg#EMprintW05CondensedRegular_web') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'EMprintW05CondensedSemibold_web';
    font-display: swap;
    src: url('EMprint W05 Condensed Semibold_web.eot');
    src: url('EMprint W05 Condensed Semibold_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Condensed Semibold_web.woff2') format('woff2'),
         url('EMprint W05 Condensed Semibold_web.woff') format('woff'),
         url('EMprint W05 Condensed Semibold_web.svg#EMprintW05CondensedSemibold_web') format('svg');
    font-weight: normal;
    font-style: normal;

}
*/

@font-face {
    font-family: 'EMprintW05LightItalic_web';
    font-display: swap;
    src: url('EMprint W05 Light Italic_web.eot');
    src: url('EMprint W05 Light Italic_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Light Italic_web.woff2') format('woff2'),
         url('EMprint W05 Light Italic_web.woff') format('woff'),
         url('EMprint W05 Light Italic_web.svg#EMprintW05LightItalic_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'EMprintW05Light_web';
    font-display: swap;
    src: url('EMprint W05 Light_web.eot');
    src: url('EMprint W05 Light_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Light_web.woff2') format('woff2'),
         url('EMprint W05 Light_web.woff') format('woff'),
         url('EMprint W05 Light_web.svg#EMprintW05Light_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'EMprintW05RegularItalic_web';
    font-display: swap;
    src: url('EMprint W05 Regular Italic_web.eot');
    src: url('EMprint W05 Regular Italic_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Regular Italic_web.woff2') format('woff2'),
         url('EMprint W05 Regular Italic_web.woff') format('woff'),
         url('EMprint W05 Regular Italic_web.svg#EMprintW05RegularItalic_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'EMprintW05Regular_web';
    font-display: swap;
    src: url('EMprint W05 Regular_web.eot');
    src: url('EMprint W05 Regular_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Regular_web.woff2') format('woff2'),
         url('EMprint W05 Regular_web.woff') format('woff'),
         url('EMprint W05 Regular_web.svg#EMprintW05Regular_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'EMprintW05SemiboldItalic_web';
    font-display: swap;
    src: url('EMprint W05 Semibold Italic_web.eot');
    src: url('EMprint W05 Semibold Italic_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Semibold Italic_web.woff2') format('woff2'),
         url('EMprint W05 Semibold Italic_web.woff') format('woff'),
         url('EMprint W05 Semibold Italic_web.svg#EMprintW05SemiboldItalic_web') format('svg');
    font-weight: normal;
    font-style: normal;

}

/*
@font-face {
    font-family: 'EMprintW05Semibold_web';
    font-display: swap;
    src: url('EMprint W05 Semibold_web.eot');
    src: url('EEMprint W05 Semibold_web.eot?#iefix') format('embedded-opentype'),
         url('EMprint W05 Semibold_web.woff2') format('woff2'),
         url('EMprint W05 Semibold_web.woff') format('woff'),
         url('EMprint W05 Semibold_web.svg#EMprintW05Semibold_web') format('svg');
    font-weight: normal;
    font-style: normal;

}
*/
